import { DisplayType } from '@shared/types/enums';
import { ThemeGridColumnWidth, ThemeWidthProp } from '@ui-v2/types/layout';
import { ThemeSpacing } from '@ui-v2/types/props';

export const legacyBreakpoints = {
  [DisplayType.ExtraExtraSmall]: 320,
  [DisplayType.ExtraSmall]: 480,
  [DisplayType.Small]: 768,
  [DisplayType.Medium]: 960,
  [DisplayType.Large]: 1024,
  [DisplayType.ExtraLarge]: 1280,
  [DisplayType.ExtraExtraLarge]: 1420,
};

export const breakpoints: [number, number, number, number, number, number] = [
  480, 768, 960, 1024, 1280, 1420,
];

export const gridColumnWidths: ThemeGridColumnWidth = {
  '0': 0,
  '100%': '100%',
  '1/2': `${(1 / 2) * 100}%`,
  '1/3': `${(1 / 3) * 100}%`,
  '2/3': `${(2 / 3) * 100}%`,
  '1/4': `${(1 / 4) * 100}%`,
  '2/4': `${(2 / 4) * 100}%`,
  '3/4': `${(3 / 4) * 100}%`,
  '1/5': `${(1 / 5) * 100}%`,
  '2/5': `${(2 / 5) * 100}%`,
  '3/5': `${(3 / 5) * 100}%`,
  '4/5': `${(4 / 5) * 100}%`,
  '1/6': `${(1 / 6) * 100}%`,
  '2/6': `${(2 / 6) * 100}%`,
  '3/6': `${(3 / 6) * 100}%`,
  '4/6': `${(4 / 6) * 100}%`,
  '5/6': `${(5 / 6) * 100}%`,
  '1/7': `${(1 / 7) * 100}%`,
  '2/7': `${(2 / 7) * 100}%`,
  '3/7': `${(3 / 7) * 100}%`,
  '4/7': `${(4 / 7) * 100}%`,
  '5/7': `${(5 / 7) * 100}%`,
  '6/7': `${(6 / 7) * 100}%`,
  '1/8': `${(1 / 8) * 100}%`,
  '2/8': `${(2 / 8) * 100}%`,
  '3/8': `${(3 / 8) * 100}%`,
  '4/8': `${(4 / 8) * 100}%`,
  '5/8': `${(5 / 8) * 100}%`,
  '6/8': `${(6 / 8) * 100}%`,
  '7/8': `${(7 / 8) * 100}%`,
  '1/9': `${(1 / 9) * 100}%`,
  '2/9': `${(2 / 9) * 100}%`,
  '3/9': `${(3 / 9) * 100}%`,
  '4/9': `${(4 / 9) * 100}%`,
  '5/9': `${(5 / 9) * 100}%`,
  '6/9': `${(6 / 9) * 100}%`,
  '7/9': `${(7 / 9) * 100}%`,
  '8/9': `${(8 / 9) * 100}%`,
  '1/10': `${(1 / 10) * 100}%`,
  '2/10': `${(2 / 10) * 100}%`,
  '3/10': `${(3 / 10) * 100}%`,
  '4/10': `${(4 / 10) * 100}%`,
  '5/10': `${(5 / 10) * 100}%`,
  '6/10': `${(6 / 10) * 100}%`,
  '7/10': `${(7 / 10) * 100}%`,
  '8/10': `${(8 / 10) * 100}%`,
  '9/10': `${(9 / 10) * 100}%`,
  '1/11': `${(1 / 11) * 100}%`,
  '2/11': `${(2 / 11) * 100}%`,
  '3/11': `${(3 / 11) * 100}%`,
  '4/11': `${(4 / 11) * 100}%`,
  '5/11': `${(5 / 11) * 100}%`,
  '6/11': `${(6 / 11) * 100}%`,
  '7/11': `${(7 / 11) * 100}%`,
  '8/11': `${(8 / 11) * 100}%`,
  '9/11': `${(9 / 11) * 100}%`,
  '10/11': `${(10 / 11) * 100}%`,
  '11/11': `${(11 / 11) * 100}%`,
  '1/12': `${(1 / 12) * 100}%`,
  '2/12': `${(2 / 12) * 100}%`,
  '3/12': `${(3 / 12) * 100}%`,
  '4/12': `${(4 / 12) * 100}%`,
  '5/12': `${(5 / 12) * 100}%`,
  '6/12': `${(6 / 12) * 100}%`,
  '7/12': `${(7 / 12) * 100}%`,
  '8/12': `${(8 / 12) * 100}%`,
  '9/12': `${(9 / 12) * 100}%`,
  '10/12': `${(10 / 12) * 100}%`,
  '11/12': `${(11 / 12) * 100}%`,
  '12/12': `${(12 / 12) * 100}%`,
};

export const sizes: ThemeWidthProp = {
  ...gridColumnWidths,
  full: '100%',
  container: 1280,
  viewportWidth: '100vw',
  auto: 'auto',
};

export const legacySpacings = {
  [DisplayType.ExtraExtraSmall]: 4,
  [DisplayType.ExtraSmall]: 8,
  [DisplayType.Small]: 16,
  [DisplayType.Medium]: 20,
  [DisplayType.Large]: 24,
  [DisplayType.ExtraLarge]: 36,
  [DisplayType.ExtraExtraLarge]: 48,
};

export const spacings: Record<ThemeSpacing, ThemeSpacing> = {
  '0': 0,
  '2': 2,
  '4': 4,
  '8': 8,
  '12': 12,
  '16': 16,
  '24': 24,
  '32': 32,
  '40': 40,
  '48': 48,
  '56': 56,
  '64': 64,
  '72': 72,
  '80': 80,
  auto: 'auto',
};
